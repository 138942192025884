.formContainer {
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding-top: 10px !important;
}

.sectionContainer {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.checkboxLabel {
    flex: 1;
    margin: 0 20px 0 10px !important;
    color: var(--tg-theme-text-color) !important;
}

.buttonsContainer {
    height: 50px;
    width: 100%;
    display: flex;
 }

.formButton {
   flex: 1;
   border-radius: 25px !important;
   color: var(--tg-theme-button-text-color) !important;
}

.cancelButton {
    margin: 0 10px 0 20px !important;
    border-color: var(--tg-theme-button-text-color) !important;
}

.saveButton {
    margin: 0 20px 0 10px !important;
    background-color: var(--tg-theme-button-color) !important;
}

.saveButton:disabled {
    background-color: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-subtitle-text-color) !important;
}