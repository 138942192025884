.productListContainer {
  background-color: var(--tg-theme-secondary-bg-color);
}

.header {
  width: 100%;
  background-color: var(--tg-theme-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  margin-left: 10px;
}

.location {
  display: flex;
  align-items: center;
}

.iconButton {
  color: var(--tg-theme-button-color);
}

.headerIcon {
  color: var(--tg-theme-button-color);
}

.buttonSaveAddress {
  min-height: 50px;
}

.productList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
  gap: 8px;
  padding: 8px;
  background-color: var(--tg-theme-secondary-bg-color);
}

@media (max-width: 850px) {
  .productList {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr));
  }
}

@media (max-width: 550px) {
  .productList {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
  }
}

@media (max-width: 300px) {
  .productList {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% - 20px), 1fr));
  }
}

.iconSuccess {
  color: #1cd363;
}

.successWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px 0;
  height: 110px;
  gap: 16px;
}

.emptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}

.emptyPlaceholderImg {
  margin: 10px 0 20px;
}

.emptyPlaceholderTitle {
  font-size: 22px !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  color: var(--tg-theme-text-color);
}

.emptyPlaceholderSubtitle {
  font-size: 18px !important;
  color: var(--tg-theme-hint-color);
  max-width: 200px;
}
