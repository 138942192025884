.productImageContainer {
    background-color: var(--tg-theme-secondary-bg-color);
    display: flex;
    justify-content: center;
   }
   
   .productImageContainer img {
     max-width: 100%;
   }
   
   .headerText {
     cursor: pointer;
     color: var(--tg-theme-section-header-text-color)
   }
   
   .headerIcon{
     color: var(--tg-theme-button-color);
   }

   .amount {
     display: flex;
     flex-direction: row;
     align-items: flex-end;
   }

   .editButton {
     padding: 4px !important;
     margin-left: 4px !important;
   }

   .editIcon {
     color: var(--tg-theme-button-color);
   }
   
   /* .arrowBack{
     display: flex;
     align-items: center;
   } */
   
   .productDetailsContainer{
     background-color: var(--tg-theme-section-bg-color);
   }
   
   .productDetails {
     padding-top: 56px;
   }
   
   .productName {
       padding-left: 16px;
       padding-top: 16px;
       text-align: left;
       color: var(--tg-theme-section-header-text-color)
   }
   
   .productDescription {
       padding-left: 16px;
       text-align: left;
       color: var(--tg-theme-text-color);
   }
   
   .footer {
     bottom: 80px;
     padding-top: 16px;
     padding-bottom: 16px;
     position: fixed;
     width: 100%;
     /* background-color: var(--tg-theme-header-bg-color); */
     background-color: var(--tg-theme-section-bg-color);
     border-radius: 26px 26px 0px 0px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 2);
     display: flex;
     align-items: center;
     justify-content: space-between;
     z-index: 2;
   }
   
   .addButton {
     width: 50%;
     padding-right: 16px;
   }
   
   .addButton button {
     height: 50px;
     border-radius: 25px;
   }
   
   